// @ts-nocheck
import './style.css'
import InfiniteScroll from 'infinite-scroll';
import pkceChallenge from "./pkce";
import Cookies from 'js-cookie';
import axios from 'axios';
import {gql, GraphQLClient} from 'graphql-request';
import {marked} from 'marked';
import { initializeApp, FirebaseApp } from "firebase/app";
import Alpine from 'alpinejs';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import EmojiConvertor from "emoji-js";

dayjs.extend(relativeTime);

declare global {
    interface Window {
        Alpine: Alpine;
        Firebase: FirebaseApp;
    }
}

const siteId = 9;
const clientId = 7;
const redirectUrl = 'https://sukanz.com/';

const firebaseConfig = {
    apiKey: "AIzaSyB6xMyzx9qJeAMsNrQ7IprP8WzhBKEByTI",
    authDomain: "amanz-future.firebaseapp.com",
    projectId: "amanz-future",
    storageBucket: "amanz-future.appspot.com",
    messagingSenderId: "825286294424",
    appId: "1:825286294424:web:c0a00e0dad62d9b29789ed"
};
const app = initializeApp(firebaseConfig);
window.Firebase = app;
window.Alpine = Alpine;

Alpine.data('header', () => ({
    showSearchForm: false,
    showProfileDropdown: false,
    isUserScrolling: false,

    login() {
        // @ts-ignore
        Alpine.store('user').attemptLogin();
    },

    logout() {
        // @ts-ignore
        Alpine.store('user').logout();
    },

    openCloseSearch() {
        // @ts-ignore
        this.showSearchForm = !this.showSearchForm;
        console.log(this.showSearchForm);
    },
}));

Alpine.data('home', () => ({
    userTags: null,
    userReads: null,
    infScroll: null,
    latestComments: [],
    latestCommentsAvatars: [],
    experimentComments: [],
    posts: [],
    tabs: [],
    newTabs: [],
    categories: [],
    isAddingTab: false,
    isRemovingTab: false,
    showAddTabs: true,
    curCatPage: 1,
    maxCatPages: 0,
    isIO: false,
    isUlasan: false,

    init() {
        console.log('    .-=+*###**+-:\n-+*############=.\n.:+*#######*:\n.=*     .::\n    =##*\n+####.\n#####*:           ::\n*######+-.     .:*##*-.\n:##########***#########*=:.\n.+#########+..=############*+-\n:-===-:      :-+**###*+=-.')
        console.log('Selamat datang ke Amanz. Tema dibangunkan oleh Haikal Izzuddin');

        this.tabs = Alpine.store('user').tabs;
        if(Alpine.store('siteSettings').currentTab == -1) {
            Alpine.store('siteSettings').changeTab(0);
        }

        if(this.tabs.length < 4) {
            this.fetchCategories(1);
            this.showAddTabs = true;
        } else {
            this.showAddTabs = false;
        }

        this.fetchComments();

        const test = document.getElementById('infinite');
        this.infScroll = new InfiniteScroll( test, {
            path: function() {
                return `/wp-json/wp/v2/posts?per_page=16&page=${this.pageIndex}&_embed`;
            },
            elementScroll: false,
            responseBody: 'json',
            history: false,
            status: '.page-status'
        });

        let proxyElem = document.createElement('div');
        this.infScroll.on( 'load', (body: any[]) => {
            proxyElem.innerHTML = body.map(this.getItemHTML).join('');
            // @ts-ignore
            test.append( ...proxyElem.children );
        });

        this.infScroll.loadNextPage();
    },

    renderContent(content: string) {
        const emoji = new EmojiConvertor();
        emoji.replace_mode = 'unified';
        emoji.allow_native = true;
        return marked(emoji.replace_colons(content));
    },

    isMobile() {
        //const scale = window.devicePixelRatio;
        const mql = window.matchMedia(`(max-width: 600px)`);

        return mql.matches;
    },

    async fetchComments() {
        const query = gql`
            query fetchComments($siteID: Mixed!) {
                comments(
                    first: 10,
                    hasNode: { column: SITE_ID, operator: EQ, value: $siteID }
                ) {
                    data {
                        content
                        created_at
                        id
                        user {
                            name
                            username
                            profile_picture
                            is_verified
                            created_at
                        }
                        node {
                            id
                            title
                            url
                        }
                    }
                }
            }
        `;

        const avatarQuery = gql`
            query fetchComments($node: ID!) {
                allComments(node_id: $node) {
                    user {
                        profile_photo_url
                    }
                }
            }
        `;

        // @ts-ignore
        const data = await Alpine.store('user').graphQL.request(query, { siteID: siteId });

        const comments = [];
        for (let i = 0; i < data.comments.data.length; i++) {
            if(comments.find((comment: any) => comment.id === data.comments.data[i].node.id) === undefined) {
                const c = [];
                c.push(data.comments.data[i]);

                comments.push({
                    'id': data.comments.data[i].node.id,
                    'title': data.comments.data[i].node.title,
                    'url': data.comments.data[i].node.url,
                    'comments': c,
                    'avatars': [],
                });
            } else {
                if(comments.find((comment: any) => comment.id === data.comments.data[i].node.id).comments.length < 3) {
                    comments.find((comment: any) => comment.id === data.comments.data[i].node.id).comments.push(data.comments.data[i]);
                }
            }
        }

        for (let i = 0; i < comments.length; i++) {
            const avatars = [];
            const d = await Alpine.store('user').graphQL.request(avatarQuery, { node: comments[i].id });
            for (let j = 0; j < d.allComments.length; j++) {
                avatars.push(d.allComments[j].user.profile_photo_url);
            }

            comments[i].avatars = avatars;
        }

        this.latestComments = comments;
    },

    getItemHTML(post: any) {
        let image = '';
        if(post._embedded['wp:featuredmedia'] === undefined) {
            image = '/wp-content/themes/amanz-v7.1/public/dummy.webp';
        } else {
            image = post._embedded['wp:featuredmedia'][0].source_url ?? '/wp-content/themes/amanz-v7.1/public/dummy.webp';
        }

        if(post.sticky) {
            return `<a href="/${dayjs(post.date).year()}${post.id}" class="w-full relative group">
                    <div class="relative w-full">
                        <div class="absolute w-full h-full z-10 bg-black/30"></div>
                        <img class="aspect-[2/1] w-full h-full rounded object-cover" loading="lazy" src="${image}" alt="${post.title.rendered}" />
                    </div>
                    <div class="left-4 bottom-4 absolute flex flex-col space-y-2 z-20 w-4/5">
                        <div class="bg-white/90 px-2 py-0.5 rounded w-fit flex flex-row items-center space-x-1 text-xs">
                            <span>${post._embedded['wp:term'][0][0].name}</span>
                        </div>
                        <h2 class="lg:text-lg xl:text-xl text-white font-merriweather font-semibold leading-6 tracking-[0.5%] group-hover:underline">${post.title.rendered}</h2>
                    </div>
                </a>`;
        } else {
            return `<a href="/${dayjs(post.date).year()}${post.id}" class="group flex flex-row gap-4">
                    <div class="aspect-square w-40">
                        <img loading="lazy" src="${image}" alt="${post.title.rendered}" class="object-cover w-full h-full rounded">
                    </div>
                    <div class="w-full flex flex-col gap-2">
                        <span class="lg:text-lg xl:text-xl font-merriweather font-semibold leading-6 tracking-[0.5%] group-hover:text-red-500">${post.title.rendered}</span>
                        <div class="flex flex-row gap-2">
                            <span class="text-xs text-[#d4ac0c]">${post._embedded['wp:term'][0][0].name}</span>
                            <span class="text-xs text-gray-500">${dayjs(post.date).format('MMM DD, YYYY')}</span>
                        </div>
                    </div>
                </a>`;
        }
    },

    async fetchCategories(page: number) {
        this.categories = [];
        const res = await axios.get(
            "/wp-json/wp/v2/categories?per_page=16&page=" + page
        );

        this.maxCatPages = res.headers['x-wp-totalpages'];

        // @ts-ignore
        this.categories.push(res.data);
        this.categories = this.categories.flat();
    },

    addTab(tab: object) {
        // @ts-ignore
        const index = this.newTabs.indexOf(2);
        if(index !== -1) {
            this.newTabs.splice(index, 1);
        } else {
            // @ts-ignore
            this.newTabs.push(tab);
        }
    },

    removeTab(tab: object) {
        // @ts-ignore
        const index = this.newTabs.indexOf(2);
        if(index !== -1) {
            this.newTabs.splice(index, 1);
        } else {
            // @ts-ignore
            this.newTabs.push(tab);
        }

        this.changeTab(0);
    },

    saveTabs() {
        if(this.isRemovingTab) {
            for (let i = 0; i < this.newTabs.length; i++) {
                // @ts-ignore
                Alpine.store('user').removeTab(this.newTabs[i]);
            }
        } else {
            for (let i = 0; i < this.newTabs.length; i++) {
                // @ts-ignore
                Alpine.store('user').addTab(this.newTabs[i]);
            }
        }

        // @ts-ignore
        this.tabs = Alpine.store('user').tabs;
        this.newTabs = [];
        this.isAddingTab = false;
        this.isRemovingTab = false;
    },

    toggleAddTab(isAdding: boolean, isRemoving: boolean) {
        this.newTabs = [];
        if(isAdding && !isRemoving) {
            this.fetchCategories(1);
            this.isAddingTab = !this.isAddingTab;
        } else if(!isAdding && isRemoving) {
            this.isRemovingTab = !this.isAddingTab;
        } else {
            this.isAddingTab = false;
            this.isRemovingTab = false;
        }
    },

    ulasan() {
        this.isIO = false;
        this.isUlasan = true;
        // @ts-ignore
        Alpine.store('siteSettings').changeTab(-1);
        const test = document.getElementById('infinite');
        // @ts-ignore
        test.innerHTML = '';
        // @ts-ignore
        this.infScroll.destroy();
        // @ts-ignore
        this.infScroll = new InfiniteScroll( test, {
            path: function() {
                // @ts-ignore
                return `/wp-json/wp/v2/posts?per_page=16&tags=2274&page=${this.pageIndex}&_embed`;
            },
            elementScroll: false,
            responseBody: 'json',
            history: false,
            status: '.page-status'
        });

        let proxyElem = document.createElement('div');
        // @ts-ignore
        this.infScroll.on( 'load', (body: any[]) => {
            proxyElem.innerHTML = body.map(this.getItemHTML).join('');
            // @ts-ignore
            test.append( ...proxyElem.children );
        });

        // @ts-ignore
        this.infScroll.loadNextPage();
    },

    eventsTab() {
        this.isIO = true;
        this.isUlasan = false;
        // @ts-ignore
        Alpine.store('siteSettings').changeTab(-1);
        const test = document.getElementById('infinite');
        // @ts-ignore
        test.innerHTML = '';
        // @ts-ignore
        this.infScroll.destroy();
        // @ts-ignore
        this.infScroll = new InfiniteScroll( test, {
            path: function() {
                // @ts-ignore
                return `/wp-json/wp/v2/posts?per_page=16&categories=23787&page=${this.pageIndex}&_embed`;
            },
            elementScroll: false,
            responseBody: 'json',
            history: false,
            status: '.page-status'
        });

        let proxyElem = document.createElement('div');
        // @ts-ignore
        this.infScroll.on( 'load', (body: any[]) => {
            proxyElem.innerHTML = body.map(this.getItemHTML).join('');
            // @ts-ignore
            test.append( ...proxyElem.children );
        });

        // @ts-ignore
        this.infScroll.loadNextPage();
    },

    changeTab(tab: number) {
        this.isIO = false;
        this.isUlasan = false;

        // @ts-ignore
        Alpine.store('siteSettings').changeTab(tab);

        const test = document.getElementById('infinite');
        // @ts-ignore
        test.innerHTML = '';
        // @ts-ignore
        this.infScroll.destroy();
        // @ts-ignore
        this.infScroll = new InfiniteScroll( test, {
            path: function() {
                if(tab === 0) {
                    // @ts-ignore
                    return `/wp-json/wp/v2/posts?per_page=16&page=${this.pageIndex}&_embed`;
                } else {
                    // @ts-ignore
                    return `/wp-json/wp/v2/posts?per_page=16&categories=${tab}&page=${this.pageIndex}&_embed`;
                }
            },
            elementScroll: false,
            responseBody: 'json',
            history: false,
            status: '.page-status'
        });

        let proxyElem = document.createElement('div');
        // @ts-ignore
        this.infScroll.on( 'load', (body: any[]) => {
            proxyElem.innerHTML = body.map(this.getItemHTML).join('');
            // @ts-ignore
            test.append( ...proxyElem.children );
        });

        // @ts-ignore
        this.infScroll.loadNextPage();
    },

    formatDate(date: string) {
        return dayjs(date).format('MMM DD, YYYY')
    },

    async share(title: string, link: string) {
        const shareData = {
            title: title,
            url: link,
        };

        try {
            await navigator.share(shareData);
        } catch (err) {
            console.log(`Error: ${err}`)
        }
    },

    resetTabs() {
        // @ts-ignore
        Alpine.store('user').resetTabs();
        // @ts-ignore
        this.tabs = Alpine.store('user').tabs;
    },
}));

Alpine.data('single', () => ({
    postId: null,
    textCopied: false,
    isCommentsOpen: false,
    comments: null,
    isSharing: false,
    commentInput: '',
    replyingTo: null,
    replyingToID: null,
    userTags: null,
    recommended: [],
    recommendedReady: false,

    init() {
        console.log('Hi anda di sebalik skrin. Mempunyai masalah? Hubungi kami di https://amanz.my/kami');
    },

    openCloseComments() {
        this.isCommentsOpen = !this.isCommentsOpen;
    },

    openCloseSharing() {
        this.isSharing = !this.isSharing;
    },

    convertEmoji(comment: string) {
        var emoji = new EmojiConvertor();
        emoji.replace_mode = 'unified';
        emoji.allow_native = true;
        return emoji.replace_colons(comment);
    },

    twitterShare(title: string, link: string) {
        window.open(
            "https://twitter.com/intent/tweet?text=" + encodeURIComponent(title) + "&url=" + encodeURIComponent(link) + "&via=amanz",
            "sharer-twitter",
            "height=400,width=600"
        );
    },

    facebookShare(title: string, link: string) {
        window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(link) + "&text=" + encodeURIComponent(title),
            "sharer-facebook",
            "height=400,width=600"
        );
    },

    whatsappShare(title: string, link: string) {
        window.open(
            "whatsapp://send?text=" + encodeURIComponent(link) + " - " + encodeURIComponent(title),
            "sharer-whatsapp",
        );
    },

    telegramShare(title: string, link: string) {
        window.open(
            "https://t.me/share/url?url=" + encodeURIComponent(link) + "&text=" + encodeURIComponent(title),
            "sharer-telegram",
            "height=400,width=600"
        );
    },

    copyLink(link: string) {
        navigator.clipboard.writeText(link).then(() => {
            this.textCopied = true;
            setTimeout(() => (this.textCopied = false), 2000);
        });
    },

    formatDate(date: string, format: string) {
        return dayjs(date).format(format);
    },

    fromNow(date: string) {
        dayjs.extend(relativeTime);
        return dayjs(date).fromNow();
    },

    renderContent(content: string) {
        const emoji = new EmojiConvertor();
        emoji.replace_mode = 'unified';
        emoji.allow_native = true;
        return marked(emoji.replace_colons(content));
    },

    replyTo(username: string, id: string) {
        // @ts-ignore
        this.replyingTo = username;
        // @ts-ignore
        this.replyingToID = id;
    },

    submitComment(id: string) {
        const form = new FormData();
        form.append('content', this.commentInput);
        form.append('node_id', id);
        form.append('site_id', siteId);
        form.append('_method', 'PUT')

        if(this.replyingToID != null) {
            form.append('parent_id', this.replyingToID);
        }

        // @ts-ignore
        axios.post('https://amanz.me/api/comments/new', form, { headers: { 'Authorization': 'Bearer ' + Alpine.store('user').token,  'Accept': 'application/json'}}).then(() => {
            this.commentInput = '';
            // @ts-ignore
            this.fetchComment(id);
        });
    },

    async fetchRecommended(tags: [number], posts: [number]) {
        const res = await axios.get(
            "https://sukanz.com/wp-json/wp/v2/posts?_embed&order=asc&exclude="+ posts.toString() +"&per_page=3&categories=" + tags.toString()
        );

        // @ts-ignore
        this.recommended.push(res.data);
        this.recommended = this.recommended.flat()
        this.recommendedReady = true;
    },

    async fetchComment(id: string) {
        const query = gql`
            query fetchComments($id: Mixed!) {
                allComments(
                    hasNode: {
                        AND: [
                            { column: IDENTIFIER, operator: EQ, value: $id }
                            { column: SITE_ID, operator: EQ, value: 1 }
                        ]
                    }) {
                    id
                    content
                    created_at
                    user {
                        name
                        username
                        is_verified
                        profile_picture
                    }
                    children {
                        id
                        content
                        created_at
                        user {
                            name
                            username
                            is_verified
                            profile_picture
                        }
                    }
                }
            }
        `;

        // @ts-ignore
        const data = await Alpine.store('user').graphQL.request(query, { id: id });
        this.comments = data.allComments;
    },
}));

Alpine.store('siteSettings', {
    darkMode: false,
    showSearchForm: false,
    currentTab: 0,

    init() {
        if(localStorage.getItem("darkMode") === null) {
            // @ts-ignore
            this.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        } else {
            // @ts-ignore
            this.darkMode = (localStorage.getItem("darkMode") === 'true');
        }

        // @ts-ignore
        this.currentTab = localStorage.getItem("currentTab") || 0;
    },

    toggle() {
        // @ts-ignore
        this.darkMode = !this.darkMode;
        // @ts-ignore
        localStorage.setItem("darkMode", this.darkMode);
    },

    changeTab(tab: string) {
        // @ts-ignore
        this.currentTab = tab;
        // @ts-ignore
        localStorage.setItem("currentTab", tab);
    }
})

Alpine.store('user', {
    user: null,
    token: null,
    tabs: [],
    graphQL: null,

    init() {
        if(Cookies.get('_amanzme_token') !== null) {
            // @ts-ignore
            this.token = Cookies.get('_amanzme_token');

            // @ts-ignore
            this.graphQL = new GraphQLClient("https://amanz.me/graphql", {
                headers: {
                    // @ts-ignore
                    authorization: 'Bearer ' + this.token,
                },
            });

            // @ts-ignore
            this.getUserInfo();
        } else {
            // @ts-ignore
            this.graphQL = new GraphQLClient("https://amanz.me/graphql")
        }

        // @ts-ignore
        this.tabs = JSON.parse(localStorage.getItem("tabs")) || [{ name: 'Terkini', id: 0 }];
    },

    resetTabs() {
        // @ts-ignore
        this.tabs = [{ name: 'Terkini', id: 0 }];
        // @ts-ignore
        localStorage.setItem("tabs", JSON.stringify(this.tabs));
    },

    addTab(tab: object) {
        // @ts-ignore
        this.tabs.push(tab);
        // @ts-ignore
        localStorage.setItem("tabs", JSON.stringify(this.tabs));
    },

    removeTab(tab: object) {
        // @ts-ignore
        const index = this.tabs.indexOf(tab);
        // @ts-ignore
        this.tabs.splice(index, 1);
        // @ts-ignore
        localStorage.setItem("tabs", JSON.stringify(this.tabs));
    },

    attemptLogin() {
        const pkce = pkceChallenge();

        const loginQuery = "client_id="+ clientId
            + "&redirect_url="+ redirectUrl
            + "&response_type=code"
            + "&scope=profile%20openid%20comment.read%20comment.write"
            + "&state=" + pkce.state
            + "&code_challenge=" + pkce.code_challenge
            + "&code_challenge_method=S256";

        const future = new Date();
        future.setMinutes(future.getMinutes() + 10);

        Cookies.set('login_state', pkce.state, { expires: future });
        Cookies.set('login_code', pkce.code_verifier, { expires: future });

        window.location.href = "https://amanz.me/oauth/authorize?" + loginQuery;
    },

    async getToken(code: string, newState: string) {
        const state = Cookies.get("login_state");
        const verifier = Cookies.get("login_code");

        if(newState === state) {
            const formData = new FormData();
            formData.append('grant_type', 'authorization_code');
            formData.append('client_id', clientId.toString());
            formData.append('redirect_url', redirectUrl);
            formData.append('code_verifier', verifier as string);
            formData.append('code', code);

            const response = await axios.post("https://amanz.me/oauth/token", formData, );

            if (response.data.error == null) {
                const future = new Date();
                future.setDate(future.getDate() + 30);
                Cookies.set('_amanzme_token', response.data.access_token, {
                    expires: future,
                });

                window.location.href = redirectUrl;
            } else {
                console.log(response.data.error);
            }
        } else {
            console.log("Wrong state");
        }
    },

    async getUserInfo() {
        const query = gql`
            query {
                me {
                    name
                    username
                    email
                    is_verified
                    profile_picture
                    bio
                }
            }
        `;

        // @ts-ignore
        const data = await this.graphQL.request(query);

        // @ts-ignore
        this.user = data.me;
        sessionStorage.setItem("user", JSON.stringify(data.me));
    },

    logout() {
        // @ts-ignore
        this.user = null;
        sessionStorage.setItem("user", "null");

        // @ts-ignore
        this.token = null;
        Cookies.remove('_amanzme_token');
    }
});

Alpine.start();